import React, { useEffect } from "react"
import { LinkItem } from "../../sanity/types"
import { Link } from "../link/Link"
import buildLink from "../../sanity/buildLink"
import { Button } from "../button/Button"
import { useBus } from "react-bus"
import { trackGTMEvent } from "../../lib/tagmanager"

interface LinkHandlerProps {
    link: LinkItem
    children?: React.ReactNode
    arrow?: boolean
    pill?: boolean
    style?: React.CSSProperties
    className?: string
    appearance?: "secondary" | "dark" | "light"
    locale?: string
    target?: string
    rel?: string
    prefetch?: boolean
    handleOnPointerEnter?: () => void
    handleOnPointerLeave?: () => void
    onMouseDown?: (any) => void
}

export function LinkHandler({ link: rawLink, children, handleOnPointerEnter, handleOnPointerLeave, onMouseDown, prefetch, ...props }: LinkHandlerProps) {
    const bus = useBus()
    const [link, setLink] = React.useState<LinkItem>(rawLink)
    useEffect(() => {
        if (rawLink?.linkType === "external") {
            const passparams = rawLink?.linkType === "external" && rawLink.url.includes("?passparams")
            setLink({
                ...rawLink,
                ...(passparams ? { url: rawLink.url.replace("?passparams", location.search || "?") } : {}),
            })
        } else {
            setLink(rawLink)
        }
    }, [rawLink])

    if (!link?.linkType) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { arrow, pill, ...childProps } = props
        return children ? <div {...childProps}>{children}</div> : (null)
    }

    if (link.linkType === "event") {
        const handleEvent = () => {
            bus.emit(link.event, link?.title)

            if (link.gtmEvent) {
                trackGTMEvent(link.gtmEvent)
            }
        }

        if (!children) {
            return (
                <Button {...props} onClick={handleEvent} handleOnPointerEnter={handleOnPointerEnter} handleOnPointerLeave={handleOnPointerLeave} onMouseDown={onMouseDown}>{link.title}</Button>
            )
        }

        return (
            <span role="button" tabIndex={0} {...props} onClick={handleEvent} onPointerEnter={handleOnPointerEnter} onPointerLeave={handleOnPointerLeave} onMouseDown={onMouseDown}>{children}</span>
        )
    }

    return (
        <Link
            href={buildLink(link)}
            onClick={link.gtmEvent ? () => trackGTMEvent(link.gtmEvent) : undefined}
            handleOnPointerEnter={handleOnPointerEnter} handleOnPointerLeave={handleOnPointerLeave}
            onMouseDown={onMouseDown}
            arrow={link.withArrow}
            markAsNew={link.markAsNew}
            target={link.linkType === "external" && !link.url.startsWith("#") && !link.url.startsWith("/") && !link.url.includes("www.lunar.app")  ? "_blank" : props.target}
            prefetch={link.linkType === "external" ? false : prefetch}
            locale={link.linkType === "external" ? false : props.locale}
            {...props}
        >
            {/* Awkwardly add a span wrapper with nosnippet attribute to phone numbers */}
            {
                (link?.linkType === "external" && link?.url?.startsWith("tel:"))
                    ? (
                        <span data-nosnippet="true">{children || link.title}</span>
                    ) : (
                        children || link.title
                    )
            }
        </Link>
    )
}
